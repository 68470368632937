import DrawerHandle from '@/components/drawerHandle';
import { FormGraphQLAutoCompleteProps } from '@/components/form/fields/types';
import Loading from '@/components/loading';
import SearchBar from '@/components/searchBar';
import { useInfiniteGraphQL } from '@/data';
import { useModalControls } from '@/providers/modal';
import {
	Box,
	Button,
	Collapse,
	DialogActions,
	DialogContent,
	DialogTitle,
	Fade,
	Stack,
	Typography,
} from '@mui/material';
import { merge } from 'lodash-es';
import React, { Fragment, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { useDebouncedValue } from 'rooks';

export function BottomSheetAutocomplete<T extends { id?: string }, Variables = any>(
	props: Omit<FormGraphQLAutoCompleteProps<T, Variables>, 'name'> ) {
	const { closeModal } = useModalControls();
	const [ search, setSearch ] = useState( '' );
	const [ selected, setSelected ] = useState<T[]>( props.multiple ? props.value as T[] : [] );
	const [ debouncedSearch ] = useDebouncedValue<string | ''>( search, 500 );
	
	const {
		      flattenedData,
		      fetchNextPage,
		      hasNextPage,
		      isFetching,
		      count,
	      } = useInfiniteGraphQL<Variables>( {
		query    : props.query,
		queryKey : props.queryKey,
		variables: debouncedSearch ? merge( { options: { search: debouncedSearch } }, props.variables ) : props.variables,
	}, props.options as any );
	
	const handleItemClick = ( e: React.MouseEvent, item: T ) => {
		if ( props.multiple ) {
			setSelected( ( prevSelected ) => {
				const alreadySelected = prevSelected.some( ( selectedItem ) => selectedItem.id === item.id );
				const updatedSelection = alreadySelected
					? prevSelected.filter( ( selectedItem ) => selectedItem.id !== item.id )
					: [ ...prevSelected, item ];
				props.setValue( updatedSelection );
				return updatedSelection;
			} );
		} else {
			props.setValue( item );
			props.onChange?.( e, item, undefined );
			closeModal();
		}
	};
	
	return (
		<Fragment>
			<DialogTitle sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<DrawerHandle sx={{ mb: 1, mt: 0 }}/>
				{props.textFieldProps?.label && (
					<Typography fontWeight='bold' textAlign='center' sx={{ mb: 1 }}>
						{`Select ${props.textFieldProps.label || ''}`}
					</Typography>
				)}
				<SearchBar
					autoFocus
					fullWidth
					setSearch={setSearch}
					placeholder='Search'
					value={search}
				/>
			</DialogTitle>
			<DialogContent
				dividers
				sx={{ p: 0, overflowX: 'hidden' }}>
				<Virtuoso<T>
					data={flattenedData}
					endReached={async () => {
						if ( hasNextPage && !isFetching ) await fetchNextPage();
					}}
					itemContent={( index, data ) => {
						const isSelected = selected.some( ( selectedItem ) => selectedItem.id === data.id );
						return (
							<Fade in timeout={500}>
								<Box
									key={index}
									sx={{
										mx          : .5,
										borderRadius: 2,
										bgcolor     : isSelected ? 'alpha.primary' : 'transparent',
										color       : isSelected ? 'secondary.main' : 'text.primary',
									}}
									onClick={( e ) => handleItemClick( e, data )}>
									{props?.renderOption?.( undefined, data, undefined, undefined )}
								</Box>
							</Fade>
						);
					}}
					components={{
						Footer: () => hasNextPage || isFetching ? <Loading/> : count > 10 ? (
							<Typography sx={{ textAlign: 'center', color: 'text.secondary' }}>
								No more results
							</Typography>
						) : null,
					}}
				/>
			</DialogContent>
			{( Boolean( props.onAdd ) || selected.length > 0 ) && (
				<DialogActions sx={{ borderTop: 1, borderColor: 'divider' }}>
					<Stack spacing={1} m={1} width='100%'>
						<Collapse unmountOnExit in={selected.length > 0}>
							<Button
								sx={{ width: '100%' }}
								variant='contained'
								color='primary'
								onClick={( e ) => {
									props.onChange?.( e, selected, undefined );
									closeModal();
								}}>
								Add {selected.length} Selected
							</Button>
						</Collapse>
						{Boolean( props.onAdd ) && (
							<Button
								sx={{ bgcolor: 'alpha.success', width: '100%' }}
								variant='text'
								color='success'
								onClick={props.onAdd}>
								Create New
							</Button>
						)}
					</Stack>
				</DialogActions>
			)}
		</Fragment>
	);
}
