import FormGraphQLAutocomplete from '@/components/form/fields/formGraphQLAutocomplete';
import { FormGraphQLAutoCompleteProps } from '@/components/form/fields/types';
import { SalesAgentsRead } from '@/data/salesAgent.graphql';
import { useCustomerFromPartnerId } from '@/providers/auth/useSessionStoragePartnerId';
import type { QuerySalesAgentsReadArgs, SalesAgent } from '@/types/schema';
import { MenuItem } from '@mui/material';

export default function SalesAgentSelect( props: Omit<FormGraphQLAutoCompleteProps<SalesAgent, QuerySalesAgentsReadArgs>, 'query' | 'queryKey'> ) {
	const { sessionCustomerFromPartnerId } = useCustomerFromPartnerId();
	
	return (
		<FormGraphQLAutocomplete<SalesAgent>
			label='Sales Agents'
			queryKey={[ 'salesAgent' ]}
			query={SalesAgentsRead}
			variables={{
				options: {
					limit : 10,
					filter: {
						partner: sessionCustomerFromPartnerId,
						user   : { $ne: null },
					},
				},
			}}
			getOptionLabel={( salesAgent: SalesAgent ) => `${salesAgent.firstName} ${salesAgent.lastName}`}
			renderOption={( props, option ) => (
				<MenuItem key={option.id ?? ''} {...props}>
					{`${option.firstName} ${option.lastName}`}
				</MenuItem>
			)}
			{...props}
			textFieldProps={{ label: props.label || 'Sales Agents', ...props.textFieldProps }}
		/>
	);
}
