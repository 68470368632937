import { BaseGraphQLAutocompleteProps } from '@/components/form/fields/types';
import TextFieldInputLabel from '@/components/form/inputLabel';
import { BottomSheetAutocomplete } from '@/components/responsiveAutoComplete/bottomSheetSelect';
import { useModal } from '@/providers/modal';
import { Box, Button, FormHelperText, ListItem, ListItemText, Paper, Stack, Typography } from '@mui/material';
import { isArray } from 'lodash-es';
import React from 'react';

export default function MobileBottomSheetSelect<T extends {
	id?: string
}, Variables = any>( props: BaseGraphQLAutocompleteProps<T, Variables> ) {
	const { showModal } = useModal();
	const isMultiple = props.multiple && isArray( props.value ) && props.value?.length;
	return (
		<Stack
			width='100%'
			spacing={1}
			p={isMultiple ? 1 : 0}
			my={1}
			component={isMultiple ? Paper : undefined}>
			<Box>
				{props.textFieldProps?.label && (
					<TextFieldInputLabel
						label={props.textFieldProps?.label || ''}
					/>
				)}
				<Box
					sx={{
						width         : '100%',
						px            : 1.5,
						py            : 1,
						borderRadius  : 2,
						border        : 1,
						borderColor   : 'divider',
						bgcolor       : 'background.default',
						display       : 'flex',
						alignItems    : 'center',
						justifyContent: 'start',
					}}
					onClick={() => showModal( BottomSheetAutocomplete, { fullPageBottomSheet: true }, props )}>
					{props.multiple || !props.value
						? <Typography color='text.secondary'>{props.textFieldProps?.placeholder || 'Select'}</Typography>
						: <Typography>{props.getOptionLabel?.( props.value as any )}</Typography>}
				</Box>
				{props.textFieldProps?.helperText && (
					<FormHelperText>
						{props.textFieldProps?.helperText || ''}
					</FormHelperText>
				)}
			</Box>
			{props.multiple ? isArray( props.value ) && props.value?.map( ( val, index ) => (
				<ListItem key={index} divider={index !== ( isArray( props.value ) && props.value?.length - 1 )}>
					<ListItemText primary={props.getOptionLabel( val )}/>
					<Button
						variant='text'
						color='error'
						onClick={() => {
							props.setValue( ( props.value as any ).filter( ( v, vIndex ) => index !== vIndex ) );
						}}>
						Delete
					</Button>
				</ListItem>
			) ) : undefined}
		</Stack>
	);
}

