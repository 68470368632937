import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import useControlledState from '../hooks/useControlledState';

type C = {
	setActiveStep: Dispatch<SetStateAction<number>>,
	activeStep: number
};

const StepperContext = createContext<C>( null );
StepperContext.displayName = 'Stepper';

export default function StepperProvider( { children } ) {
	const [ activeStep, setActiveStep ] = useControlledState( 0 );
	
	return (
		<StepperContext.Provider value={{ setActiveStep, activeStep }}>
			{children}
		</StepperContext.Provider>
	);
}

export function useStepper() {
	return useContext( StepperContext );
}

export function withStepper( Component ) {
	return ( props ) => (
		<StepperContext.Consumer>
			{( stepper ) => <Component stepper={stepper} {...props}/>}
		</StepperContext.Consumer>
	);
}
