import LayoutAnimation, { LayoutAnimationStaggerParent } from '@/components/framerAnimations/layoutAnimation';
import PageLink from '@/components/page/link';
import StyledImage from '@/components/styledImage';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import useGetDeviceInfo from '../../../hooks/useGetDeviceInfo';

export default function LeftGridLayout() {
	const theme = useTheme();
	const { isCloverDevice } = useGetDeviceInfo();
	
	return (
		<Box
			sx={{
				height        : '100%',
				width         : { xs: '100%', md: 500 },
				display       : 'flex',
				flexDirection : 'column',
				justifyContent: 'space-between',
			}}>
			<LayoutAnimationStaggerParent>
				<Stack spacing={2}>
					<LayoutAnimation hasStagger variant='slideUp'>
						<Typography variant='h1' style={{ fontSize: '45px' }}>
							Start your journey right now with Invoiss!
						</Typography>
					</LayoutAnimation>
					<LayoutAnimation hasStagger variant='slideUp'>
						<Typography style={{ fontSize: '20px' }}>
							A platform designed with both business and client in mind.
							With Innovation and simplicity at the forefront of our business we aim to fulfill every
							corner of business essentials!
						</Typography>
						<Typography style={{ fontSize: '20px' }}>One Innovation at a Time!</Typography>
					</LayoutAnimation>
				</Stack>
			</LayoutAnimationStaggerParent>
			<Stack
				direction='row'
				alignItems='center'
				sx={{ display: { xs: 'none', md: 'flex' } }}
				justifyContent='space-between'>
				<StyledImage
					alt='banner'
					src={`/images/invoiss-${theme.palette.mode === 'dark' ? 'logowhite' : 'logoblack'}.png`}
					sx={{ borderRadius: 3, width: 120 }}
				/>
				<Stack direction='row' spacing={1} alignItems='center'>
					<PageLink
						underline='hover'
						color={theme.palette.mode === 'dark' ? '#ffffff' : '#000000'}
						href='/terms'
						target={!isCloverDevice ? '_blank' : ''}>
						Terms
					</PageLink>
					<Typography>
						•
					</Typography>
					<PageLink
						underline='hover'
						color={theme.palette.mode === 'dark' ? '#ffffff' : '#000000'}
						href='/privacy'
						target={!isCloverDevice ? '_blank' : ''}>
						Privacy
					</PageLink>
				</Stack>
			</Stack>
		</Box>
	);
}
