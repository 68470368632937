import { BaseGraphQLAutocompleteProps } from '@/components/form/fields/types';
import MobileBottomSheetSelect from '@/components/responsiveAutoComplete/mobileBottomSheetSelect';
import WebAutoComplete from '@/components/responsiveAutoComplete/webAutoComplete';
import { Theme, useMediaQuery } from '@mui/material';

export default function ResponsiveGraphQlAutoComplete<T extends { id?: string }, Variables = any>( {
	queryKey,
	query,
	variables,
	subscription,
	options,
	textFieldProps,
	clearTextOnInputChange,
	PaperComponent,
	onAdd,
	...props
}: BaseGraphQLAutocompleteProps<T, Variables> ) {
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	
	if ( isMobile ) {
		return (
			<MobileBottomSheetSelect
				queryKey={queryKey}
				query={query}
				variables={variables}
				subscription={subscription}
				options={options}
				textFieldProps={textFieldProps}
				clearTextOnInputChange={clearTextOnInputChange}
				PaperComponent={PaperComponent}
				onAdd={onAdd}
				{...props}
			/>
		);
	}
	return (
		<WebAutoComplete
			queryKey={queryKey}
			query={query}
			variables={variables}
			subscription={subscription}
			options={options}
			textFieldProps={textFieldProps}
			clearTextOnInputChange={clearTextOnInputChange}
			PaperComponent={PaperComponent}
			onAdd={onAdd}
			{...props}
		/>
	);
}

