import { BaseGraphQLAutocompleteProps } from '@/components/form/fields/types';
import FormattedTextField from '@/components/formattedTextField';
import { useGraphQL } from '@/data';
import { Autocomplete, Typography } from '@mui/material';
import { isEmpty, merge } from 'lodash-es';
import React, { useMemo, useState } from 'react';
import { useDebouncedValue } from 'rooks';

export default function WebAutoComplete<T extends {
	id?: string
}, Variables = any>( {
	queryKey,
	query,
	variables,
	subscription,
	options,
	textFieldProps,
	clearTextOnInputChange,
	PaperComponent,
	onAdd,
	...props
}: BaseGraphQLAutocompleteProps<T, Variables> ) {
	const [ inputValue, setInputValue ] = useState( props.inputValue || '' );
	const [ delayedInputValue ] = useDebouncedValue( inputValue, 500 );
	
	const { data, isLoading } = useGraphQL<Variables>( {
		queryKey    : queryKey,
		query       : query,
		variables   : delayedInputValue
			? merge( { options: { search: delayedInputValue } }, variables )
			: variables,
		subscription: subscription,
	}, options );
	
	const PaperWithData = useMemo( () => ( props ) => (
		<PaperComponent
			data={data}
			isLoading={isLoading}
			{...props}
		/>
	), [ props.open, isLoading ] );
	
	return (
		<Autocomplete<T, any, any, any>
			selectOnFocus
			openOnFocus
			blurOnSelect
			handleHomeEndKeys
			filterOptions={( options ) => options}
			disableCloseOnSelect={props.multiple}
			options={!isEmpty( data ) ? ( Object.values( data )[ 0 ] as any ).items : []}
			renderInput={( params ) => (
				<FormattedTextField {...textFieldProps} {...params}/>
			)}
			loading={isLoading}
			isOptionEqualToValue={( option, value ) => option?.id === value?.id}
			ChipProps={{ variant: 'alpha' }}
			noOptionsText={(
				<Typography textAlign='center'>
					No Options
				</Typography>
			)}
			{...props}
			inputValue={inputValue}
			PaperComponent={PaperWithData}
			onChange={( e, newValue: any, reason, details ) => {
				if ( props.multiple ) {
					props.setValue?.( newValue?.filter( Boolean ) );
				} else {
					props.setValue?.( newValue );
				}
				props.onChange?.( e, newValue, reason, details );
			}}
			onInputChange={( e, value, reason ) => {
				if ( reason === 'reset' && clearTextOnInputChange ) return;
				props.onInputChange?.( e, value, reason );
				setInputValue( value );
			}}
		/>
	);
}

