import CustomCheckBox from '@/components/customCheckBox';
import FormattedTextField from '@/components/formattedTextField';
import { useStepper } from '@/providers/stepper';
import { ArrowForwardIosRounded as ArrowForwardIosRoundedIcon } from '@mui/icons-material';
import {
	Button,
	Collapse,
	Grid,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Stack,
	Tooltip,
	Typography,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { industries, referralSources } from '../p/connect/companydetailsStep';

export default function CompanyExtraDetails( { onBoarding }: { onBoarding?: boolean } ) {
	const formik = useFormikContext<any>();
	const { setActiveStep, activeStep } = useStepper();
	
	const [ otherReferralSource, setOtherReferralSource ] = useState( '' );
	const [ isOtherSelected, setIsOtherSelected ] = useState( false );
	
	return (
		<Stack spacing={3}>
			<Stack spacing={1}>
				<Typography variant='h1' style={{ fontSize: '40px' }}>
					Tell us a little about your company
				</Typography>
				<Typography style={{ fontSize: '18px' }} color='text.secondary'>
					This wont take long, we promise.
				</Typography>
			</Stack>
			<Stack spacing={1}>
				<Typography style={{ fontSize: '18px' }}>
					Whats your industry?
				</Typography>
				<Grid container spacing={1} sx={onBoarding ? { width: { md: 500 } } : undefined}>
					{industries.map( ( industry, index ) => (
						<Grid key={index} item xs={12} md={4}>
							<Tooltip
								placement={index % 2 === 0 ? 'left' : 'right'}
								title={industry.detail}>
								<ListItemButton
									selected={formik.values.company?.industry === industry.key}
									sx={{
										'border'        : 2,
										'height'        : 80,
										'alignItems'    : 'center',
										'justifyContent': 'center',
										'borderColor'   : 'divider',
										'borderRadius'  : 2,
										'bgcolor'       : 'background.default',
										'&.Mui-selected': {
											'borderColor'        : 'primary.main',
											'.MuiTypography-root': {
												color: 'primary.main',
											},
										},
									}}
									onClick={() => formik.setFieldValue( 'company.industry', industry.key )}>
									<ListItemText
										primary={industry.industry}
										primaryTypographyProps={{ fontSize: '15px !important' }}
										sx={{ textAlign: 'center' }}
									/>
								</ListItemButton>
							</Tooltip>
						</Grid>
					) )}
				</Grid>
			</Stack>
			<Stack spacing={1}>
				<Typography style={{ fontSize: '18px' }}>
					How did you hear about us?
				</Typography>
				<Stack spacing={1}>
					{referralSources.map( ( ref, index ) => (
						<ListItemButton
							key={index}
							disableGutters
							sx={{ left: 10, width: { md: 200 } }}
							onClick={() => {
								if ( ref === 'Other' ) {
									setIsOtherSelected( true );
								} else {
									setIsOtherSelected( false );
								}
								formik.setFieldValue( 'company.referralSource', ref );
							}}>
							<ListItemIcon sx={{ minWidth: 35 }}>
								<CustomCheckBox checked={formik.values.company?.referralSource === ref || isOtherSelected && ref === 'Other'}/>
							</ListItemIcon>
							<ListItemText primary={ref}/>
						</ListItemButton>
					) )}
					<Collapse in={isOtherSelected}>
						<FormattedTextField
							multiline
							rows={2}
							inputProps={{ maxLength: 40 }}
							sx={{
								left : 10,
								width: { md: 300 },
							}}
							placeholder='Let us know how you heard about us'
							value={otherReferralSource}
							onChange={( e ) => {
								setOtherReferralSource( e.target.value );
								formik.setFieldValue( 'company.referralSource', `Other: ${e.target.value}` );
							}}
						/>
					</Collapse>
				</Stack>
			</Stack>
			{onBoarding && (
				<Button
					variant='contained'
					endIcon={<ArrowForwardIosRoundedIcon/>}
					color='primary'
					sx={{ width: 'max-content', left: 15 }}
					size='large'
					disabled={!formik.values.company?.industry
						|| !formik.values.company?.referralSource
						|| formik.values.company?.referralSource === 'Other' && !otherReferralSource}
					onClick={() => setActiveStep( activeStep + 1 )}>
					Let's Go Next
				</Button>
			)}
		</Stack>
	);
}
