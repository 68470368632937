import type { LinkProps as MuiLinkProps } from '@mui/material';
import { Link as MuiLink } from '@mui/material';
import { forwardRef } from 'react';
import type { PageLinkComponentProps } from './linkComponent';
import PageLinkComponent from './linkComponent';

export type PageLinkProps = PageLinkComponentProps & Omit<MuiLinkProps, 'href'>;

const PageLink = forwardRef<HTMLAnchorElement, PageLinkProps>( function ( props, ref ) {
	return (
		<MuiLink
			ref={ref}
			component={PageLinkComponent}
			{...props}
		/>
	);
} );
export default PageLink;
