import Form from '@/components/form';
import { useStepper } from '@/providers/stepper';
import { Box, Button, Container, Grid, LinearProgress, Stack, Theme, useMediaQuery } from '@mui/material';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { CompanySource, Industry } from '../../types/schema';
import CompanyFormDetails, { companyValidationSchema } from './company';
import CompanyExtraDetails from './companyExtraDetails';
import LeftGridLayout from './fragments/leftGridLayout';
import LastStep from './gateway';
import useNewCompanyHandleSubmit from './handleSubmit';
import WelcomeInitialStep from './initialStep';
import AddressFormDetails, { locationValidationSchema } from './location';

export const onboardingFormValidationSchemas = [
	companyValidationSchema,
	locationValidationSchema,
];

export const onBoardingFormInitialValues = {
	company     : {
		email         : '',
		name          : '',
		contact       : '',
		source        : 'WEBSITE' as CompanySource,
		phone         : '',
		cell          : '',
		industry      : '' as Industry,
		referralSource: '' as string,
	},
	location    : {
		name    : '',
		timezone: Intl.DateTimeFormat?.().resolvedOptions().timeZone || '',
		address : {
			line1     : '',
			line2     : '',
			city      : '',
			state     : '',
			postalCode: '',
			country   : '',
			lat       : null,
			lng       : null,
		},
	},
	refererAgent: null,
	
};

export default function WelcomeOnBoardingForm() {
	const { setActiveStep, activeStep } = useStepper();
	const breakPoint = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.up( 'md' ) );
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'lg' ) );
	
	const handleSubmitForm = useNewCompanyHandleSubmit();
	
	useEffect( () => {
		if ( breakPoint ) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}
	}, [ breakPoint ] );
	
	return (
		<Form
			validationSchema={onboardingFormValidationSchemas[ activeStep - 1 ]}
			initialValues={onBoardingFormInitialValues}
			onSubmit={async ( values ) => await handleSubmitForm( values )}>
			<Container disableGutters maxWidth={false} sx={{ height: '100vh', mt: { md: '-49px' } }}>
				<Grid container sx={{ height: { lg: '100%' } }}>
					{!isMobile && (
						<Grid
							item
							xs={12}
							lg={5}
							sx={{
								py           : { xs: 10, md: 15 },
								px           : { xs: 2, sm: 8 },
								display      : 'flex',
								flexDirection: 'column',
								alignItems   : { xs: 'center', lg: 'end' },
							}}>
							<LeftGridLayout/>
						</Grid>
					)}
					<Grid
						item
						xs={12}
						lg={7}
						sx={{
							bgcolor      : 'divider',
							display      : 'flex',
							flexDirection: 'column',
							alignItems   : { xs: 'center', lg: 'start' },
							overflowX    : 'hidden !important',
							overflowY    : breakPoint && 'scroll !important',
							maxHeight    : breakPoint && '100vh',
						}}>
						<Stack
							spacing={3}
							sx={{
								height: 'auto',
								py    : { xs: 5, sm: 10, md: 15 },
								px    : { xs: 2, sm: 10 },
							}}>
							{activeStep !== 0 && (
								<Box>
									<Button
										size='large'
										variant='text'
										sx={{ borderRadius: 5 }}
										onClick={() => setActiveStep( activeStep - 1 )}>
										Go Back
									</Button>
								</Box>
							)}
							{activeStep === 0 && <WelcomeInitialStep/>}
							{activeStep !== 0 && (
								<LinearProgress
									color='inherit'
									sx={{
										'height'                            : 30,
										'width'                             : 250,
										'borderRadius'                      : 5,
										'.MuiLinearProgress-bar1Determinate': {
											bgcolor: '#ffffff',
										},
									}}
									variant='determinate'
									value={activeStep * 25}
								/>
							)}
							{activeStep !== 0 && (
								<motion.div
									key={
										activeStep === 1 ? 'Company'
											: activeStep === 2 ? 'Location'
												: activeStep === 3 ? 'CompanyExtra'
													: 'Gateway'
									}
									initial={{ x: 10, opacity: 0 }}
									animate={{ x: 0, opacity: 1 }}
									exit={{ x: -10, opacity: 0 }}
									transition={{ duration: .5 }}>
									{activeStep === 1 && <CompanyFormDetails onBoarding/>}
									{activeStep === 2 && <AddressFormDetails onBoarding/>}
									{activeStep === 3 && <CompanyExtraDetails onBoarding/>}
									{activeStep === 4 && <LastStep/>}
								</motion.div>
							)}
						</Stack>
					</Grid>
				</Grid>
			</Container>
		</Form>
	);
}
