import FormPhoneField from '@/components/form/fields/phoneField';
import FormTextField from '@/components/form/fields/textField';
import handleGoNext from '@/helpers/formikValidateBeforeSubmit';
import { useStepper } from '@/providers/stepper';
import { ArrowForwardIosRounded as ArrowForwardIosRoundedIcon } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import * as yup from 'yup';

export const companyValidationSchema = yup.object().shape( {
	company: yup.object().shape( {
		email  : yup
			.string()
			.email( 'Invalid email address' )
			.required( 'Email is required' ),
		name   : yup
			.string()
			.required( 'Enter a company name' ),
		contact: yup
			.string()
			.required( 'Enter a contact name' ),
	} ),
} );

export default function CompanyFormDetails( { onBoarding }: { onBoarding?: boolean } ) {
	const { setActiveStep, activeStep } = useStepper();
	const formik = useFormikContext<any>();
	
	return (
		<Stack spacing={3}>
			<Stack spacing={1}>
				<Typography variant='h1' style={{ fontSize: '40px' }}>
					Create your company
				</Typography>
				<Typography style={{ fontSize: '18px' }}>
					All your client communications will originate from these information
				</Typography>
			</Stack>
			<Stack spacing={2} width={onBoarding ? { xs: '100%', md: 300 } : undefined}>
				<FormTextField
					fullWidth
					name='company.name'
					label='Company Name'
					size='medium'
				/>
				<FormTextField
					fullWidth
					name='company.contact'
					label='Full Name'
					size='medium'
				/>
				<FormTextField
					fullWidth
					name='company.email'
					label='Company Email'
					size='medium'
				/>
				<FormPhoneField
					name='company.phone'
					label='Phone'
					size='medium'
					onChange={( value, data, event, formattedValue ) => {
						formik.setFieldValue( 'company.phone', formattedValue.length < 3
							? ''
							: formattedValue );
					}}
				/>
				<FormPhoneField
					name='company.cell'
					label='Cell'
					tooltip='Add your cell number to receive text messages upon any payment by your client'
					size='medium'
					onChange={( value, data, event, formattedValue ) => {
						formik.setFieldValue( 'company.cell', formattedValue.length < 3
							? ''
							: formattedValue );
					}}
				/>
			</Stack>
			{onBoarding && (
				<Box mt={5}>
					<Button
						color='primary'
						size='large'
						endIcon={<ArrowForwardIosRoundedIcon/>}
						onClick={async () => await handleGoNext( setActiveStep, formik, activeStep, 4 )}>
						Go Next
					</Button>
				</Box>
			)}
		</Stack>
	);
}
