import { AnimationProps, motion, TargetAndTransition, VariantLabels, Variants } from 'framer-motion';
import { ReactNode } from 'react';

export type AnimationVariant =
	'slideUp'
	| 'slideDown'
	| 'slideRight'
	| 'slideLeft'
	| 'fadeIn'
	| 'fadeInRight'
	| 'fadeInLeft'
	| 'fadeInRight2'
	| 'fadeInLeft2'
	| 'fadeInUp'
	| 'fadeInDown';

export function LayoutAnimationStaggerParent( { children, ...props }: { children: any } & AnimationProps ) {
	return (
		<motion.div
			initial='offscreen'
			whileInView='onscreen'
			viewport={{ once: true, amount: 0.1 }}
			transition={{ staggerChildren: 0.5 }}
			{...props}>
			{children}
		</motion.div>
	);
}

export default function LayoutAnimation( {
	children,
	animationConfig,
	variant = 'slideUp',
	slideUpDistance = 20,
	duration = 1.5,
	hasStagger,
	viewport,
	initial,
	animate,
	exit,
	whileInView,
	...props
}: {
	children: ReactNode,
	variant?: AnimationVariant,
	slideUpDistance?: number,
	duration?: number,
	hasStagger?: boolean,
	viewport?: {
		once?: boolean,
		amount?: number
		// ... any other expected properties
	},
	initial?: string,
	animate?: string,
	exit?: string,
	whileInView?: TargetAndTransition | VariantLabels,
	animationConfig?: Variants,
	[ key: string ]: any // This allows any additional props
} & AnimationProps ) {
	
	const defaultAnimationVariants = {
		slideUp     : {
			offscreen: { y: slideUpDistance, opacity: 0 },
			onscreen : {
				y         : 0,
				opacity   : 1,
				transition: {
					type    : 'spring',
					duration: duration,
				},
			},
		},
		slideDown   : {
			offscreen: { y: -slideUpDistance, opacity: 0 },
			onscreen : {
				y         : 0,
				opacity   : 1,
				transition: {
					type    : 'spring',
					duration: duration,
				},
			},
		},
		slideRight  : {
			offscreen: { x: 20, opacity: 0 },
			onscreen : {
				x         : 0,
				opacity   : 1,
				transition: {
					type    : 'spring',
					duration: duration,
				},
			},
		},
		slideLeft   : {
			offscreen: { x: -20, opacity: 0 },
			onscreen : {
				x         : 0,
				opacity   : 1,
				transition: {
					type    : 'spring',
					duration: duration,
				},
			},
		},
		fadeIn      : {
			offscreen: {
				opacity   : 0,
				transition: { duration: duration },
			},
			onscreen : {
				opacity   : 1,
				transition: {
					duration: duration,
				},
			},
			exit     : {
				opacity   : 0,
				transition: { duration: duration },
			},
		},
		fadeInRight : {
			offscreen: {
				x         : 10,
				opacity   : 0,
				transition: { duration: .5 },
			},
			onscreen : {
				x         : 0,
				opacity   : 1,
				transition: { duration: .5 },
			},
			exit     : {
				x         : -10,
				opacity   : 0,
				transition: { duration: .5 },
			},
		},
		fadeInLeft  : {
			offscreen: {
				x         : -10,
				opacity   : 0,
				transition: { duration: .5 },
			},
			onscreen : {
				x         : 0,
				opacity   : 1,
				transition: { duration: .5 },
			},
			exit     : {
				x         : 10,
				opacity   : 0,
				transition: { duration: .5 },
			},
		},
		fadeInRight2: {
			offscreen: {
				x         : 5,
				opacity   : .2,
				transition: { duration: .3 },
			},
			onscreen : {
				x         : 0,
				opacity   : 1,
				transition: { duration: .3 },
			},
			exit     : {
				x         : -5,
				opacity   : .2,
				transition: { duration: .3 },
			},
		},
		fadeInLeft2 : {
			offscreen: {
				x         : -5,
				opacity   : .2,
				transition: { duration: .3 },
			},
			onscreen : {
				x         : 0,
				opacity   : 1,
				transition: { duration: .3 },
			},
			exit     : {
				x         : 5,
				opacity   : .2,
				transition: { duration: .3 },
			},
		},
		fadeInUp    : {
			offscreen: {
				x         : 0,
				y         : 10,
				opacity   : 0,
				transition: { duration: .5 },
			},
			onscreen : {
				x         : 0,
				y         : 0,
				opacity   : 1,
				transition: { duration: .5 },
			},
			exit     : {
				x         : 10,
				opacity   : 0,
				transition: { duration: .5 },
			},
		},
		fadeInDown  : {
			offscreen: {
				x         : 0,
				y         : -10,
				opacity   : 0,
				transition: { duration: .5 },
			},
			onscreen : {
				x         : 0,
				y         : 0,
				opacity   : 1,
				transition: { duration: .5 },
			},
			exit     : {
				x         : 10,
				opacity   : 0,
				transition: { duration: .5 },
			},
		},
	};
	
	const selectedVariant: Variants = animationConfig || defaultAnimationVariants[ variant ];
	
	return (
		<motion.div
			variants={selectedVariant}
			initial={initial !== undefined ? initial : hasStagger ? undefined : 'offscreen'}
			animate={animate !== undefined ? animate : hasStagger ? undefined : 'onscreen'}
			exit={exit !== undefined ? exit : hasStagger ? undefined : 'offscreen'}
			whileInView={whileInView}
			viewport={viewport}
			{...props}>
			{children}
		</motion.div>
	);
}
