import { Clear as ClearIcon, Search as SearchIcon } from '@mui/icons-material';
import { Box, IconButton, InputAdornment, SvgIconProps, TextField, TextFieldProps } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useThrottle } from 'react-use';
import { useDebounce } from 'rooks';
import TextFieldInputLabel from './form/inputLabel';

export default function SearchBar( {
	setSearch,
	searchText,
	delay,
	searchIconProps,
	label,
	...props
}: {
	setSearch?: ( value: string ) => void,
	searchText?: string,
	delay?: number,
	searchIconProps?: SvgIconProps
} & TextFieldProps ) {
	const [ search, _setSearch ] = useState( '' );
	const onChange = useDebounce( ( value ) => setSearch?.( value || undefined ), delay );
	
	const mountedRef = useRef( false );
	
	const delayed = useThrottle( search, delay );
	
	useEffect( () => {
		if ( !mountedRef.current ) {
			mountedRef.current = true;
			return;
		}
		setSearch?.( delayed );
	}, [ delayed ] );
	
	return (
		<Box width='100%'>
			<TextFieldInputLabel label={label}/>
			<TextField
				size='small'
				value={searchText || search}
				InputProps={{
					startAdornment: (
						<InputAdornment position='start'>
							<SearchIcon {...searchIconProps}/>
						</InputAdornment>
					),
					endAdornment  : searchText || search ? (
						<InputAdornment position='end'>
							<IconButton
								sx={{ p: 0 }}
								onClick={() => {
									_setSearch( '' );
									onChange( '' );
								}}>
								<ClearIcon/>
							</IconButton>
						</InputAdornment>
					) : null,
				}}
				onChange={( e ) => _setSearch( e.target.value )}
				{...props}
			/>
		</Box>
	);
}
