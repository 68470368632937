import { FormGraphQLAutoCompleteProps } from '@/components/form/fields/types';
import ResponsiveGraphQlAutoComplete from '@/components/responsiveAutoComplete';
import { Button, CircularProgress, Grow, Paper, Typography } from '@mui/material';
import { useField } from 'formik';
import { isEmpty } from 'lodash-es';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function FormGraphQLAutocomplete<T extends { id?: string }, Variables = any>( {
	name,
	label,
	onAdd,
	subscription,
	textFieldProps,
	clearTextOnInputChange,
	onChange, // by destructuring onChange, it will not be in the props object anymore. So it cant be overwritten.
	...props
}: FormGraphQLAutoCompleteProps<T, Variables> ) {
	const { t } = useTranslation();
	const [ , { touched, error, value }, { setValue, setTouched } ] = useField( name );
	const [ open, setOpen ] = useState( false );
	
	function PaperComponent( { children, data, isLoading } ) {
		const dataSet = Object.values( data )[ 0 ] as any;
		//@ts-ignore
		const limit = props.variables?.options?.limit;
		const items = !isEmpty( data ) && dataSet?.count >= ( limit ?? 10 ) ? dataSet?.count : dataSet?.items?.length;
		
		return (
			<Grow in={open}>
				<Paper
					variant='blur'
					sx={{
						display      : 'flex',
						flexDirection: 'column',
					}}>
					{onAdd ? (
						<Button
							sx={{ m: 1, bgcolor: 'alpha.success' }}
							variant='text'
							color='success'
							onMouseDown={( e ) => e.preventDefault()}
							onClick={onAdd}>
							{t( 'common:create-new' )}
						</Button>
					) : undefined}
					{isLoading ?? <CircularProgress size={100}/>}
					{children}
					{items >= ( limit ?? 10 ) ? (
						<Typography
							textAlign='center'
							color='text.secondary'
							onMouseDown={( e ) => e.preventDefault()}>
							{t( 'commerce:search-to-see-more' )}
						</Typography>
					) : undefined}
				</Paper>
			</Grow>
		);
	}
	
	return (
		<ResponsiveGraphQlAutoComplete
			open={open}
			clearTextOnInputChange={clearTextOnInputChange}
			value={value ?? ( props.multiple ? [] : null )}
			setValue={setValue}
			textFieldProps={{
				error     : touched && Boolean( error ),
				isError   : touched && Boolean( error ),
				label     : touched && error || label,
				helperText: touched && error || textFieldProps?.helperText || undefined,
				...textFieldProps,
			}}
			getOptionLabel={( option ) => props.getOptionLabel?.( option ) || option.name}
			renderOption={( optionProps, option ) => (
				<li {...optionProps} key={option.id ?? ''}>
					{props.getOptionLabel?.( option ) || option.name}
				</li>
			)}
			PaperComponent={PaperComponent}
			onAdd={onAdd}
			onOpen={() => setOpen( true )}
			onClose={() => setOpen( false )}
			onChange={( e, newValue, reason, details ) => {
				setTouched( true );
				if ( clearTextOnInputChange ) {
					setValue( props.multiple ? [] : null );
				}
				onChange?.( e, newValue, reason, details );
			}}
			{...props}
		/>
	);
}
