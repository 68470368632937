import { Dispatch, SetStateAction } from 'react';
import applyTouchState from './formikTouchAllFields';

export default async function handleGoNext(
	setActiveStep: Dispatch<SetStateAction<number>>,
	formik: any,
	step: number,
	finalStep: number,
) {
	const errors = await formik.validateForm();
	
	if ( Object.keys( errors ).length === 0 ) {
		if ( step === finalStep ) {
			formik.handleSubmit();
		} else {
			setActiveStep( step + 1 );
		}
	} else {
		const touchedFields = applyTouchState( errors );
		formik.setTouched( touchedFields );
	}
}
