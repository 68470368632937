import CustomCheckBox from '@/components/customCheckBox';
import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import FormattedTextField from '@/components/formattedTextField';
import LayoutAnimation from '@/components/framerAnimations/layoutAnimation';
import { ArrowForwardIosRounded as ArrowForwardIosRoundedIcon } from '@mui/icons-material';
import {
	Grid,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Select,
	Stack,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useState } from 'react';

const currencies = [ 'USD', 'CAD', 'EUR' ];

type Industry = {
	industry: string,
	key: string,
	detail: string
};

export const industries: Industry[] = [
	{
		industry: 'Food & Beverage',
		key     : 'FOODBEVERAGE',
		detail  : 'Restaurants, Bars, Cafes, Food Trucks, Bakeries, Delis, Winery Vineyards',
	}, {
		industry: 'Retail',
		key     : 'RETAIL',
		detail  : 'Retail Stores, Boutiques, Supermarkets, Department Stores, Malls',
	}, {
		industry: 'Construction',
		key     : 'CONSTRUCTION',
		detail  : 'Construction firms, Contractors, Land Developers, Property Managers, Architects',
	}, {
		industry: 'Education',
		key     : 'EDUCATION',
		detail  : 'Education institutions such as Schools, Colleges, Tutoring Centers, Online Learning Platforms',
	}, {
		industry: 'Healthcare',
		key     : 'HEALTHCARE',
		detail  : 'Healthcare Institutions, Doctors, Clinics, Hospitals, Pharmacies, Dental Practices',
	}, {
		industry: 'Legal',
		key     : 'LEGAL',
		detail  : 'Legal firms, Lawyers, Notaries, Paralegals, Legal Aid Services',
	}, {
		industry: 'Finance',
		key     : 'FINANCE',
		detail  : 'Finance Firms, Accounting Practices, Investment Companies, Banks, Credit Unions',
	}, {
		industry: 'Services',
		key     : 'SERVICES',
		detail  : 'Cleaning Services, Landscaping Companies, IT Services, Consultants, Plumbing Companies, Electricians, HVAC Services, Maintenance Services',
	}, /*{
		industry: 'Manufacturing',
		key     : 'MANUFACTURING',
		detail  : 'Production, Transportation Equipment, Machinery, Fabricated Metal Products, Electrical Equipment, Appliances, and Components',
	},*/
];

export const referralSources = [
	'Clover App Store',
	'Social Media',
	'Email',
	'Google Search',
	'Via a friend',
	'Other',
];

export default function CompanyDetailsStep() {
	const formik = useFormikContext<any>();
	const theme = useTheme();
	const [ otherReferralSource, setOtherReferralSource ] = useState( '' );
	const [ isOtherSelected, setIsOtherSelected ] = useState( false );
	
	return (
		<LayoutAnimation key='companyDetails' variant='fadeInRight'>
			<Stack spacing={2}>
				<Stack spacing={.5}>
					<Typography variant='h1' style={{ fontSize: '40px' }}>
						Tell us a little about your company
					</Typography>
					<Typography style={{ fontSize: '18px' }} color='text.secondary'>
						This wont take long, we promise.
					</Typography>
				</Stack>
				<Stack spacing={1}>
					<Typography style={{ fontSize: '18px' }}>
						What currency do you use?
					</Typography>
					<Select
						placeholder='Currency'
						value={formik.values.currency}
						sx={{ width: 300, left: 10 }}
						onChange={( e ) => formik.setFieldValue( 'currency', e.target.value )}>
						{currencies.map( ( currency ) => (
							<MenuItem key={currency} value={currency}>
								<ListItemText primary={currency}/>
							</MenuItem>
						) )}
					</Select>
				</Stack>
				<Stack spacing={1}>
					<Typography style={{ fontSize: '18px' }}>
						Whats your industry?
					</Typography>
					<Grid container spacing={1} sx={{ width: { md: 500 } }}>
						{industries.map( ( industry, index ) => (
							<Grid key={index} item xs={12} md={3}>
								<Tooltip
									placement={index % 2 === 0 ? 'top-start' : 'top-end'}
									title={industry.detail}>
									<ListItemButton
										selected={formik.values.industry === industry.key}
										sx={{
											'border'        : 2,
											'height'        : 60,
											'alignItems'    : 'center',
											'justifyContent': 'center',
											'borderColor'   : 'divider',
											'borderRadius'  : 2,
											'bgcolor'       : 'background.default',
											'&.Mui-selected': {
												'borderColor'        : 'primary.main',
												'.MuiTypography-root': {
													color: 'primary.main',
												},
											},
										}}
										onClick={() => formik.setFieldValue( 'industry', industry.key )}>
										<ListItemText
											primary={industry.industry}
											primaryTypographyProps={{ fontSize: '15px !important' }}
											sx={{ textAlign: 'center' }}
										/>
									</ListItemButton>
								</Tooltip>
							</Grid>
						) )}
					</Grid>
				</Stack>
				<Stack spacing={1}>
					<Typography style={{ fontSize: '18px' }}>
						How did you hear about us?
					</Typography>
					<Stack spacing={1}>
						{referralSources.map( ( ref, index ) => (
							<ListItemButton
								key={index}
								disableGutters
								sx={{ left: 10, width: { md: 200 }, p: 0 }}
								onClick={() => {
									if ( ref === 'Other' ) {
										setIsOtherSelected( true );
									} else {
										setIsOtherSelected( false );
									}
									formik.setFieldValue( 'referralSource', ref );
								}}>
								<ListItemIcon sx={{ minWidth: 35 }}>
									<CustomCheckBox checked={formik.values.referralSource === ref || isOtherSelected && ref === 'Other'}/>
								</ListItemIcon>
								<ListItemText primary={ref}/>
							</ListItemButton>
						) )}
						{isOtherSelected && (
							<FormattedTextField
								multiline
								rows={2}
								inputProps={{ maxLength: 40 }}
								sx={{
									left : 10,
									width: { md: 300 },
								}}
								placeholder='Let us know how you heard about us'
								value={otherReferralSource}
								onChange={( e ) => {
									setOtherReferralSource( e.target.value );
									formik.setFieldValue( 'referralSource', `Other: ${e.target.value}` );
								}}
							/>
						)}
					</Stack>
				</Stack>
				<AsyncLoadingButton
					variant='contained'
					endIcon={<ArrowForwardIosRoundedIcon/>}
					color={theme.palette.mode === 'dark' ? 'primary' : 'alpha'}
					sx={{ width: 'max-content', left: 15 }}
					size='large'
					disabled={!formik.values.currency
						|| !formik.values.industry
						|| !formik.values.referralSource
						|| formik.values.referralSource === 'Other' && !otherReferralSource}
					onClick={async () => await formik.submitForm()}>
					Let's Create your Company
				</AsyncLoadingButton>
			</Stack>
		</LayoutAnimation>
	
	);
}

