export default function applyTouchState( obj ) {
	return Object.entries( obj ).reduce( ( acc, [ key, value ] ) => {
		if ( typeof value === 'object' && value !== null ) {
			return {
				...acc,
				[ key ]: applyTouchState( value ),
			};
		}
		
		return {
			...acc,
			[ key ]: true,
		};
	}, {} );
}
