import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import SalesAgentSelect from '@/pages/formSelects/salesAgentSelect';
import { useCustomerFromPartnerId } from '@/providers/auth/useSessionStoragePartnerId';
import { SavingsRounded as SavingsRoundedIcon } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';

export default function LastStep() {
	const formik = useFormikContext<any>();
	const { sessionCustomerFromPartnerId } = useCustomerFromPartnerId();
	return (
		<Stack spacing={3}>
			<Stack spacing={1}>
				<Typography variant='h1' style={{ fontSize: '40px' }}>
					You are ready to Invoiss!
				</Typography>
				<Typography style={{ fontSize: '18px' }}>
					Welcome!
				</Typography>
			</Stack>
			{sessionCustomerFromPartnerId && (
				<Stack spacing={1}>
					<Typography color='text.secondary'>
						We realize that you were referred by a connected Partner, let us know who the sales agent was!
					</Typography>
					<SalesAgentSelect name='refererAgent'/>
				</Stack>
			)}
			<Stack spacing={2}>
				<Box>
					<AsyncLoadingButton
						disabled={formik.isSubmitting}
						startIcon={<SavingsRoundedIcon/>}
						color='primary'
						variant='contained'
						size='large'
						onClick={async () => await formik.submitForm()}>
						Go to Dashboard
					</AsyncLoadingButton>
				</Box>
			</Stack>
			<Box sx={{ height: 400 }}/>
		</Stack>
	);
}
